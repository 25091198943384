<template>
  <v-app>
    

    <dashboard-core-view />

    
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreView: () => import('./../dashboard/components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
